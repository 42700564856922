import React, { Fragment } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import moment from 'moment';

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  introDiv,
  introText,
  backgroundLogo,
  introProjects,
  introTitle,
  introProjectsContainer,
  projectCard__anchor,
  projectCard,
  projectCard__title,
  projectCard__subTitle,
  cardImage,
  projectCard__fakeButton,
  conferencesContainer,
  conferenceContainer,
  pastConferenceContainer,
  conferenceCard,
  conferenceImage,
  conferenceTextContainer,
  conferenceHeader,
  conferenceDescription,
  hr,
} from "../sass/homepage.module.scss"

const IndexPage = ({
  data: { allContentfulHomePage, allContentfulTraining },
}) => {
  const {
    introduction,
    introductionImage,
    sampleProfessionalProject,
    samplePersonalProject,
  } = allContentfulHomePage.edges[0].node

  const lastThreeEvents = allContentfulTraining.edges
    .sort((a, b) => (a.node.completionDate <= b.node.completionDate ? 1 : -1))
    .slice(0, 3)

  const introductionSpans = introduction.introduction
    .split(`<br/>`)
    .map(line => <span key={line}>{line}</span>)

  return (
    <Layout>
      <SEO />
      <div>
        <div className={introDiv}>
          <div className={introText}>{introductionSpans}</div>
          <Img
            className={backgroundLogo}
            alt="introduction"
            fluid={introductionImage.fluid}
          />
        </div>

        <div className={introProjects}>
          <div className={introTitle}>
            This is where you can find information about what I've done such as:
          </div>
          <div className={introProjectsContainer}>
            <Link to={`/professional-projects`} className={projectCard__anchor}>
              <div className={projectCard}>
                <div
                  className={projectCard__title}
                >{`The work I did for ${sampleProfessionalProject.companyName}`}</div>
                <div className={projectCard__subTitle}>
                  {sampleProfessionalProject.homePageIntro}
                </div>
                <Img
                  className={cardImage}
                  alt={`${sampleProfessionalProject.companyName} image`}
                  fluid={sampleProfessionalProject.image.fluid}
                />
                <div className={projectCard__fakeButton}>
                  See all Professional projects
                </div>
              </div>
            </Link>

            <Link to={`/personal-projects`} className={projectCard__anchor}>
              <div className={projectCard}>
                <div
                  className={projectCard__title}
                >{`The work I did on ${samplePersonalProject.title}`}</div>
                <div className={projectCard__subTitle}>
                  {samplePersonalProject.homePageIntro}
                </div>
                <Img
                  className={cardImage}
                  alt={`${samplePersonalProject.title} image`}
                  fluid={samplePersonalProject.previewImage.fluid}
                />
                <div className={projectCard__fakeButton}>
                  See all Personal projects
                </div>
              </div>
            </Link>
          </div>
        </div>

        {lastThreeEvents.length ? (
          <Fragment>
            <div className={introTitle}>
              You can also find me at conferences and coding events such as:
            </div>
            <div className={conferencesContainer}>
              {lastThreeEvents.map(({ node }, index) => {
                const eventDate = moment(node.completionDate);
                const todayDate = moment().format().substring(0,10);
                const isToday = node.completionDate === todayDate;
                const isInThePast = todayDate > moment(eventDate).format().substring(0,10);
                const dateDiff = isToday ? 'Today' : isInThePast ? 'Past' : eventDate.startOf('day').fromNow();

                return (
                  <div key={node.title} className={isInThePast ? pastConferenceContainer : conferenceContainer}>
                    <div className={conferenceCard}>
                      <Img
                        className={conferenceImage}
                        alt={`${node.title} image`}
                        fluid={node.image.fluid}
                      />
                      <div className={conferenceTextContainer}>
                        <div className={conferenceHeader}>
                          <div>{node.title}</div>
                          <div>{`${eventDate.format('MMMM Do YYYY')} (${dateDiff})`}</div>
                        </div>
                        <div className={conferenceDescription}>
                          {node.description.description}
                        </div>
                      </div>
                    </div>
                    {index !== lastThreeEvents.length - 1 && (
                      <div className={hr}></div>
                    )}
                  </div>
                )}
              )}
            </div>
          </Fragment>
        ) : null}
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    allContentfulHomePage {
      edges {
        node {
          introduction {
            introduction
          }
          introductionImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          sampleProfessionalProject {
            companyName
            image {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            homePageIntro
          }
          samplePersonalProject {
            title
            previewImage {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            homePageIntro
          }
        }
      }
    }
    allContentfulTraining(filter: { type: { eq: "Conference" } }) {
      edges {
        node {
          title
          image {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          description {
            description
          }
          link
          completionDate
        }
      }
    }
  }
`
